
import { defineComponent, ref, reactive, UnwrapRef } from "vue";
import { RuleObject } from 'ant-design-vue/es/form/interface';
import { useRouter } from "vue-router";
import { accountService } from "@/_services";
import { IUpdatePasswordPayload, IUpdatePwdDto } from "@/_utils/types";
import AlertModal from "@/components/AlertModal.vue";
import { CheckCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useAuth } from "@/_modules/auth";

export default defineComponent({
  components: {
    AlertModal,
    CheckCircleFilled,
    ExclamationCircleOutlined
  },
  setup() {
    const { logout } = useAuth()
    const router = useRouter();
    const visibleModal = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const alertMessage = ref<string>("ยืนยันสิทธ์ของท่านแล้ว");
    const alertType = ref<"success"|"danger">()
    const formRef = ref();
    const formState: UnwrapRef<IUpdatePasswordPayload> = reactive({
      password: "",
      confirmPassword: "",
      oldPassword: "",
    });

    const validateOldPass = async (rule: RuleObject, value: string) => {
      if (value === '') {
        // return Promise.reject('Please input the password');
      }
      return Promise.resolve();
    };
    let validatePass = async (rule: RuleObject, value: string) => {
      if (value === '') {
        // return Promise.reject('Please input the password');
      } else {
        // if (!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)){
        //   return Promise.reject('รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก และตัวเลข');
        // }
        if (formState.confirmPassword !== '') {
          formRef.value.validateFields('confirmPassword');
        }
        return Promise.resolve();
      }
    };
    let validatePass2 = async (rule: RuleObject, value: string) => {
      if (value === '') {
        // return Promise.reject('Please input the password again');
      } else if (value !== formState.password) {
        return Promise.reject("Password doesn't match!");
      } else {
        return Promise.resolve();
      }
    };
    const formRules = {
      oldPassword: [{ validator: validateOldPass, trigger: 'change' }, { required: true, message: 'Please input current password'}],
      password: [{ validator: validatePass, trigger: 'change' }, { required: true, message: 'Please input new password'}],
      confirmPassword: [{ validator: validatePass2, trigger: 'change' }, { required: true, message: 'Please input new password again'}],
    };
    
    const handleFormFinish = () => {
      if(!formState.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)){
        alertType.value = 'danger'
        alertMessage.value = 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก และตัวเลข'
        visibleModal.value = true
        return
      }
      const payload: IUpdatePwdDto = {
        account_password: formState.password,
        confirm_password: formState.confirmPassword,
        old_password: formState.oldPassword,
      }
      loading.value = true
      accountService.updatePassword(payload)
      .then(result => {
        console.log('updatePassword', result)
        if(!result){
          alertType.value = 'danger'
          alertMessage.value = 'Invalid token'
          visibleModal.value = true
          return
        }
        alertMessage.value = 'Password updated successfully'
        visibleModal.value = true
        alertType.value = 'success'
      }, error => {
        console.log(error)
        const { message } = error
        alertMessage.value = Array.isArray(message) ? 'Your current password is invalid': message
        visibleModal.value = true
        alertType.value = 'danger'
      })
      .finally(() => loading.value = false)
    };

    const handleModalClose = () => {
      const type = alertType.value
      visibleModal.value = false
      setTimeout(() => {
        alertMessage.value = ''
        alertType.value = undefined
        if(type === 'success') {
          logout();
          router.replace({ name: 'login' })
        }
      }, 500)
    }

    return {
      formRef,
      formRules,
      formState,
      handleFormFinish,
      loading,
      visibleModal,
      alertMessage,
      handleModalClose,
      alertType
    };
  },
});
